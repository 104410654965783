import './vendor/_fancybox';
import './vendor/_jquery.flexslider';
import './vendor/_jquery.slicknav';
import './vendor/_remodal';

$(function () {
  $('#menu').slicknav();
  $('.slicknav_menu').prepend(
    '<a href="#login" class="login-button">Login</a>'
  );

  $('.flexslider').flexslider({
    controlNav: false,
  });

  $('.fancybox').fancybox({
    openEffect: 'fade',
    closeEffect: 'fade',
    helpers: {
      title: {
        type: 'inside',
      },
    },
  });

  function throttle(callback, time) {
    var timeout = null;
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(callback, time);
    };
  }

  function updateLoginFormEmbedHeight() {
    $('.login-form-embed').each(function () {
      var embed = $(this);
      var width = embed.width();
      var height = 281;
      if (width < 180) {
        height = 351;
      } else if (width < 205) {
        height = 335;
      } else if (width < 320) {
        height = 313;
      }
      embed.height(height);
    });
  }

  $(window).on('resize', throttle(updateLoginFormEmbedHeight, 100));
  updateLoginFormEmbedHeight();
});

function LinkAlert(url) {
  var name = 'Caldwell Bank & Trust';
  var message =
    'You are leaving ' +
    name +
    ' website and will be redirected to another site. ' +
    name +
    ' makes no endorsements or claims about the accuracy or content of the information contained in these sites. The security and privacy policies on these sites may be different than those of ' +
    name +
    '.';
  if (window.confirm(message)) {
    window.open(url, '_blank');
  }
}

window.LinkAlert = LinkAlert;
